/** @module modal */

/**
 * Initializes modal functionalities. Elements intended to open a modal should have an attribute `data-modal-open` set
 * to the ID of the modal intended to open. Elements intended to close a modal should live inside the `.modal`-element
 * and have an attribute `data-modal-close`.
 *
 * @param {object} [options={}] - Options for the modal.
 * @param {boolean} [options.blockScrolling=false] - Whether the site's scrolling is blocked while the modal is open.
 *
 * @return {Promise<void>} - Resolves immediately, if no element for opening modals is on the page, or once
 * functionality is all set up.
 */
export async function modal(options = {}) {
    if (document.querySelector('[data-modal-open]')) {
        const { initModal } = await import(/* webpackMode: "lazy" */ './inc/modal.async');

        initModal(options);
    }
}
