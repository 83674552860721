export async function validation() {
    let $forms = $('form')
        .not('[novalidate]:not(.woocommerce-checkout)')
        .not('.cart')
        .not('.woocommerce-form-login')
        .not('.woocommerce-cart-form')
        .not('.woocommerce-ordering')
        .not('.woocommerce-shipping-calculator')
        .not('.shop__add-to-cart__form')
        .not('.woocommerce-MyAccount-content form')
        .not('.woocommerce-form-coupon')
        .not('.checkout-coupon');

    if ($forms.length) {
        let { initValidation } = await import(/* webpackMode: "lazy" */ './inc/validation.async');

        return initValidation($forms);
    }
}
