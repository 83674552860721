/** @module shop */

import { initAddToCart } from './molecules/add-to-cart/add-to-cart.async';

/**
 * Initializes shop functionalities.
 *
 * @return {Promise<void>}
 */
export async function shop() {
    initAddToCart();
}
