/** @module form */

import { toElements } from '../../../../../assets/js/utils/to-elements';

/**
 * Manages states of hierarchical checkboxes.
 * Checkboxes are considered hierarchical, if they have a structure like this:
 * ul
 * - li
 * - - checkbox
 * - - ul
 * - - - li
 * - - - - checkbox
 *
 * @returns {Promise<void>} Resolves immediately if no checkboxes are present, or once the required script loaded.
 */
export async function hierarchicalCheckboxes() {
    let checkboxes = toElements('input[type="checkbox"]');

    if (checkboxes.length) {
        const { initHierarchicalCheckboxes } = await import(
            /* webpackMode: "lazy" */ './inc/hierarchical-checkboxes.async'
        );

        initHierarchicalCheckboxes(checkboxes);
    }
}
