/** @module application */

/**
 * Initializes the font size switcher(s) if any is on the page.
 * Note: Font size switchers must have set attribute `data-font-size-switcher` set to either `dynamic` or `static` (depending on the used type) on their root element.
 * Using both types on the same page is not possible.
 * @param {Object} [options={}]
 * @param {String} [options.selector='[data-font-size-switcher]'] - Selector for querying the font size switchers.
 * @param {Number} [options.steps=2] - Number of steps the font size can be increased when using the `dynamic` type.
 * @throws Throws an error if both types are used on the same page or the given type is not recognized.
 */
export function fontSizeSwitcher({ selector = '[data-font-size-switcher]', steps = 2 } = {}) {
    const switchers = document.querySelectorAll(selector);

    if (
        switchers.length &&
        switchers.length !==
            Array.prototype.filter.call(
                switchers,
                (s) => s.dataset.fontSizeSwitcher === switchers[0].dataset.fontSizeSwitcher,
            ).length
    ) {
        if (CONFIG.styleguide !== true) {
            throw '[Font Size Switcher] Only use one type of switcher on a page';
        }
    }

    switchers.forEach((switcher) => {
        switch (switcher.dataset.fontSizeSwitcher) {
            case 'dynamic':
                import(/* webpackMode: "lazy" */ './font-size-switcher--dynamic.async').then(
                    ({ initDynamicFontSwitcher }) => {
                        initDynamicFontSwitcher(switcher, selector, steps);
                    },
                );

                break;

            case 'static':
                import(/* webpackMode: "lazy" */ './font-size-switcher--static.async').then(
                    ({ initStaticFontSwitcher }) => {
                        initStaticFontSwitcher(switcher);
                    },
                );
                break;

            default:
                throw '[Font Size Switcher] Type missing.';
        }
    });
}
