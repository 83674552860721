async function manageCookieConsent() {
    if (CONFIG.cookieConsent === false) {
        const { initBorlabsCookieA11yFixes } = await import(/* webpackMode: "lazy" */ './borlabs');

        initBorlabsCookieA11yFixes();
    } else {
        const { cookieInfoAlert } = await import(/* webpackMode: "lazy" */ './cookieconsent');

        cookieInfoAlert();
    }
}

export { manageCookieConsent };
