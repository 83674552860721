/** @module add-to-cart */

import { liveEvents } from '../../../../assets/js/application';

function toggleAddToCartLoadingIndicator(added, el) {
    if (added === true) {
        el.classList.toggle('is-loading');
        el.removeChild(document.querySelector('[data-ajax-add-to-cart] span'));
    } else {
        el.classList.toggle('is-loading');
        el.insertAdjacentHTML(
            'beforeend',
            `<span class="button__loading-indicator">
    <span class="loading-indicator-item"></span>
    </span>`,
        );
    }
}
/**
 * Initializes ajax add to cart functionality for WooCommerce
 *
 * Should not be called directly.
 * @private
 * @returns {Promise<void>}
 */
export async function initAddToCart() {
    const events = await liveEvents();

    let ajaxAddToCartLinks = document.querySelectorAll('[data-ajax-add-to-cart]'),
        regularAddToCartSubmitButton = document.querySelectorAll('[data-simple-add-to-cart]'),
        inputQuantity = document.querySelectorAll('[data-add-to-cart-quantity]');

    if (ajaxAddToCartLinks.length > 0) {
        // toggle loading indicator for ajax add to cart button (jQuery needed because of woocommerce custom jquery event)
        jQuery(document.body).on('adding_to_cart', () => {
            toggleAddToCartLoadingIndicator(false, document.querySelector('[data-ajax-add-to-cart].loading'));
        });

        jQuery(document.body).on('added_to_cart', () => {
            toggleAddToCartLoadingIndicator(true, document.querySelector('[data-ajax-add-to-cart].is-loading'));
            inputQuantity.forEach((el) => {
                // reset quantity field
                el.value = 1;
            });
        });

        // hide regular submit button when JS is active
        regularAddToCartSubmitButton.forEach((submit) => {
            submit.style.display = 'none';
        });

        ajaxAddToCartLinks.forEach((el) => {
            el.style.display = 'inline-flex';
        });

        // set data-quantity of [data-ajax-add-to-cart] to current value in the quantity <input> field
        events.addEventListener('[data-add-to-cart-quantity]', 'change', (event) => {
            const condition = !event.target.value || event.target.value === '0' || isNaN(event.target.value) === true;

            event.target
                .closest('[data-shop-add-to-cart-form]')
                .querySelector('[data-ajax-add-to-cart]').dataset.quantity = condition ? '1' : event.target.value;
        });
    }
}
