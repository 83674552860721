/** @module form */

import { toElements } from '../../../../../assets/js/utils/to-elements';

/**
 * Manages the functionality of checkboxes and radiobuttons, especially handling the disabled state.
 * @return {Promise<void>} - Resolves immediately if no radiobuttons or checkboxes were found on the page, or once all
 * all inputs are initialized.
 */
export async function radioCheckboxes() {
    let radioCheckboxes = toElements('input[type="radio"], input[type="checkbox"]');

    if (radioCheckboxes.length) {
        let { initRadioCheckboxes } = await import(/* webpackMode: "lazy" */ './inc/radioCheckboxes.async');

        initRadioCheckboxes(radioCheckboxes);
    }
}
