/** @module form */

/**
 * Manages search inputs:
 * - Automatically selects the value when focused
 * - Clears the value and optionally submits the containing form when an optional clear-button is clicked
 *
 * @returns {Promise<void>} - Resolves immediately if no search inputs were found on the page, or once all inputs are
 * initialized.
 */
export async function searchInputs() {
    const inputs = document.querySelectorAll('input[type="search"]');

    if (inputs.length) {
        const { initSearchInputs } = await import(/* webpackMode: "lazy" */ './inc/searchInput.async');

        initSearchInputs(inputs);
    }
}
