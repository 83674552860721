/** @module form */

import { toElements } from '../../../../../assets/js/utils/to-elements';

/**
 * Manages automatic summaries in forms.
 *
 * @returns {Promise<void>} - Resolves immediately if no summaries were found on the page, or once all summaries in all
 *  forms are initialized.
 */
export async function summaries() {
    let summaries = toElements('[data-form-summary]');

    if (summaries.length) {
        let { initSummaries } = await import(/* webpackMode: "lazy" */ './inc/summaries.async');

        initSummaries(summaries);
    }
}
