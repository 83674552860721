export async function shopCheckoutValidation(parsleyOptions) {
    let shopCheckoutForm = document.querySelector('form.woocommerce-checkout');

    if (shopCheckoutForm) {
        let { initShopCheckoutValidation } = await import(
            /* webpackMode: "lazy" */ './inc/shopCheckoutValidation.async'
        );

        initShopCheckoutValidation(parsleyOptions);
    }
}
