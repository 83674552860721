/** @module utils */

import { eventListenerOptions } from '@spinnwerk/polyfills';

/**
 * Adds device orientation based classes to root element.
 */
export function deviceOrientation() {
    // note: FF deprecated this API
    // see https://www.fxsitecompat.dev/en-CA/docs/2018/various-device-sensor-apis-are-now-deprecated/
    window.addEventListener(
        'deviceorientation',
        ({ gamma }) => {
            let rootEl = document.documentElement,
                leftLimitMin = -50,
                rightLimitMin = 50;

            rootEl.classList.toggle('is-turned-left', gamma < leftLimitMin);
            rootEl.classList.toggle('is-turned-right', gamma > rightLimitMin);
        },
        eventListenerOptions({ passive: true }),
    );
}
