/**
 * @module application
 */

/**
 * Loads and creates the Bugsnag client, if the global <code>CONFIG.bugsnag.apiKey</code> holds a value.
 *
 * @returns {Promise<void>}
 */
export async function bugsnag() {
    if (CONFIG.bugsnag && CONFIG.bugsnag.apiKey) {
        // include bugsnag but do not execute it unless imported
        let { initBugsnag } = await import(/* webpackMode: "lazy" */ './inc/bugsnag.async');

        initBugsnag({
            apiKey: CONFIG.bugsnag.apiKey,
            releaseStage: CONFIG.env,
            appVersion: CONFIG.version || null,
        });
    }
}
