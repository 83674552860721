/** @module primary-nav */

/**
 * Checks whether a component matching selector <code>[primary-nav]</code> exists and loads further resources and
 * initializes the component, if so.
 * @param {object} [options={}] - Initial options for the component.
 * @return {Promise<void>}
 */
export async function initPrimaryNav(options = {}) {
    const primaryNav = document.querySelector('[data-primary-nav]');

    if (primaryNav) {
        const { PrimaryNav } = await import(/* webpackMode: "eager" */ './primary-nav'),
            pn = new PrimaryNav({ primaryNav, ...options });

        if (process.env.NODE_ENV !== 'production') {
            // debugging helper
            window.primaryNav = pn;
        }
    }
}
